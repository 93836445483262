<template>
  <div>
    <el-row :gutter="20" style=" margin-top: 20px; margin-bottom:20px">
      <el-col style="width:14%" v-for="item in statsCount" :key="item.key">
        <div @click="filterTag1(item.key)">
          <el-card shadow="always">
            <h3>{{ item.doc_count }}</h3>
            <div class="clearfix">
              <b v-if="item.key === 'home'">Нүүр хуудас</b>
              <b v-if="item.key === 'restaurant'">Ресторан хуудас</b>
              <b v-if="item.key === 'secret'">Нууц цэс</b>
              <el-button
                style="float: right; padding: 3px 0; color: gray"
                type="text"
                ><i class="el-icon-search"></i
              ></el-button>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <div class="page-header panel">
      <h3 class="title">
        Суртчилгааны хэрэгсэл
      </h3>
      <div class="action-section">
        <router-link to="/add-banner">
          <el-button type="success" size="mini" icon="el-icon-plus"
            >Нэмэх</el-button
          >
        </router-link>
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <el-select
              class="mr5"
              placeholder="Төлөв"
              size="mini"
              v-model="status"
              @change="activeSearch"
              clearable
            >
              <el-option value="active" key="1" label="Идэвхтэй"></el-option>
              <el-option value="default" key="0" label="Идэвхгүй"></el-option>
            </el-select>
            <el-input
              style="width: 200px;"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              @input="searchBanner"
              placeholder="Хайх суртчилгааны нэр"
            />
          </div>
          <el-table
            size="mini"
            :row-class-name="tableRowClassName"
            :data="bannerList"
          >
            <el-table-column prop="id" width="50px"></el-table-column>
            <el-table-column prop="source_url" label="Зураг" width="150px">
              <template slot-scope="scope">
                <el-image
                  :src="scope.row.source_url"
                  style="width: 100px;"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="Гарчиг"> </el-table-column>
            <el-table-column
              :formatter="formatter"
              prop="start_date"
              label="Эхлэх огноо"
              sortable
            >
            </el-table-column>
            <el-table-column
              :formatter="formatterEndDate"
              prop="end_date"
              label="Дуусах огноо"
              sortable
            >
            </el-table-column>
            <el-table-column prop="sortNumber" label="Дуусах огноо" sortable>
            </el-table-column>
            <el-table-column
              prop="status"
              label="Төлөв"
              width="100"
              :filters="[
                { text: 'Идэвхтэй', value: 'active' },
                { text: 'Идэвхгүй', value: 'default' }
              ]"
              :filter-method="filterTag"
              filter-placement="bottom-end"
            >
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  v-if="scope.row.status == 'active'"
                  type="success"
                >
                  Идэвхтэй
                </el-tag>
                <el-tag
                  size="mini"
                  v-else-if="scope.row.status == 'default'"
                  type="info"
                  >Идэвхгүй</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column label="Үйлдэл" width="150px">
              <template slot-scope="scope">
                <el-button
                  type="warning"
                  size="mini"
                  icon="el-icon-edit"
                  @click="showEdit(scope.row)"
                  >Засах</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="Үйлдэл" width="150px">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="handleDeleteBanner(scope.row.id)"
                  >Устгах</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pageCount"
            :page-size="size"
            @current-change="setPage"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :title="'Суртчилгааны нэр : ' + bannerEditData.title + ' '"
      :visible.sync="dialogVisible"
      @close="closeProgress()"
      width="55%"
      top="100px"
      class="process-dialog"
    >
      <div class="panel">
        <el-form
          :model="bannerEditData"
          :rules="rules"
          ref="bannerEditData"
          label-width="220px"
          class="demo-ruleForm"
        >
          <el-form-item
            style="width:50%"
            label="Суртчилгааны гарчиг"
            placeholder="Суртчилгааны гарчиг оруулна уу"
            prop="title"
          >
            <el-input size="mini" v-model="bannerEditData.title"></el-input>
          </el-form-item>
          <el-form-item label="Cуртчилгаа харуулах хуудас" prop="page">
            <el-select
              size="mini"
              v-model="bannerEditData.page"
              placeholder="Суртчилгааны хуудас сонгоно уу"
              @change="changePage()"
            >
              <el-option
                label="Нүүр"
                value="home"
                v-if="bannerEditData.channel !== 'driver'"
              ></el-option>
              <el-option
                v-if="bannerEditData.channel !== 'driver'"
                label="Ресторан"
                value="restaurant"
              ></el-option>
              <el-option
                v-if="bannerEditData.channel !== 'driver'"
                label="Төлбөр"
                value="payment"
              ></el-option>
              <el-option
                v-if="bannerEditData.channel !== 'driver'"
                label="Хөнгөлөлт"
                value="sales"
              ></el-option>
              <el-option
                v-if="bannerEditData.channel !== 'driver'"
                label="Тоглоом"
                value="game"
              ></el-option>
              <el-option
                v-if="bannerEditData.channel !== 'driver'"
                label="Захиалга /дэлгэрэнгүй/"
                value="order"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="
              bannerEditData.page != 'sales' || bannerEditData.page != 'order'
            "
            label="Cуртчилгааны байрлал"
            prop="position"
          >
            <el-select
              size="mini"
              v-model="bannerEditData.position"
              placeholder="Суртчилгааны байрлал сонгоно уу"
            >
              <el-option label="Дээд" value="top"></el-option>
              <el-option label="Доод" value="bottom"></el-option>
              <el-option
                v-if="bannerEditData.page === 'order'"
                label="Захиалга"
                value="order"
              ></el-option>
              <el-option
                v-if="bannerEditData.page === 'game'"
                label="Тоглоом"
                value="game"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="bannerEditData.page == 'restaurant'"
            label="Cуртчилгааны харуулах сэрвес"
            prop="position"
          >
            <el-select
              size="mini"
              v-model="bannerEditData.service"
              placeholder="Суртчилгааны сэрвес сонгоно уу"
            >
              <el-option
                v-for="service in servicesList"
                :label="service.name_mon"
                :value="service.name_eng"
                :key="service.name_eng"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Cуртчилгааны төрөл" prop="type">
            <el-select
              size="mini"
              style="width:300px"
              v-model="bannerEditData.type"
              placeholder="Суртчилгааны төрөлөө сонгоно уу"
            >
              <el-option label="Зураг" value="image"></el-option>
              <el-option label="Веб холбоостой" value="webLinking"></el-option>
              <el-option
                label="Апп дотоод холбоостой"
                value="deepLinking"
              ></el-option>
              <el-option label="Popup" value="popUp"></el-option>
              <el-option label="Найзаа урих" value="inviteFriend"></el-option>
              <el-option label="Тоглоом" value="game"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="bannerEditData.type == 'popUp'"
            label="Суртчилгааны тайлбар:"
            prop="description"
          >
            <el-input
              size="mini"
              type="textarea"
              autosize
              v-model="bannerEditData.description"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="bannerEditData.type == 'popUp'"
            label="Суртчилгааны тайлбар англи:"
            prop="description_eng"
          >
            <el-input
              size="mini"
              type="textarea"
              autosize
              v-model="bannerEditData.description_eng"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="bannerEditData.type == 'popUp'"
            label="Pop Up зураг url:"
            prop="popupImage"
          >
            <el-input
              size="mini"
              type="url"
              autosize
              v-model="bannerEditData.popupImage"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="bannerEditData.type == 'webLinking'"
            label-width="200px"
          >
            <el-form-item label="Шилжих веб хуудас url:" prop="href_url">
              <el-input
                size="mini"
                style="width:300px"
                v-model="bannerEditData.href_url"
              ></el-input>
            </el-form-item>
          </el-form-item>
          <el-form-item
            v-if="bannerEditData.type == 'deepLinking'"
            label-width="200px"
          >
            <el-form-item label="Апп шилжих хуудас" prop="route">
              <el-select
                size="mini"
                v-model="bannerEditData.route"
                @change="clearParam()"
                placeholder="Шилжих хуудас сонгоно уу"
              >
                <el-option
                  v-if="
                    bannerEditData.page !== 'home' &&
                      bannerEditData.channel === 'mobile'
                  "
                  label="Үндсэн нүүр хуудас"
                  value="HomeScreen"
                >
                </el-option>
                <el-option
                  v-if="
                    bannerEditData.page !== 'restaurant' &&
                      bannerEditData.channel === 'mobile'
                  "
                  label="Харилцагчдийн жагсаалт хуудас"
                  value="RestaurantScreen"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'mobile'"
                  label="Бүтээгдэхүүний жагсаалт хуудас"
                  value="productListScreen"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'mobile'"
                  label="Бүтээгдэхүүний дэлгэрэнгүй хуудас"
                  value="productInfoScreen"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'mobile'"
                  label="Категори хуудас"
                  value="categoryInfoScreen"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'mobile'"
                  label="Mall Нүүр хуудас"
                  value="mallHomeScreen"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'mobile'"
                  label="Mall Категори хуудас"
                  value="categoryInfoScreenMall"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'mobile'"
                  label="Mall бүтээгдэхүүн хуудас"
                  value="productInfoScreenMall"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'mobile'"
                  label="Mall брэнд хуудас"
                  value="brandInfoScreenMall"
                ></el-option>
                <el-option label="Найзаа урих" value="inviteFriend"></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'driver'"
                  label="Profile"
                  value="profile"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'driver'"
                  label="Ажиллах хуваарь"
                  value="schedule"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'driver'"
                  label="Үйлчилгэний төлбөр"
                  value="settlement"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'driver'"
                  label="Хүргэлтийн түүх"
                  value="orderHistory"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'driver'"
                  label="Тоглоом"
                  value="game"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'driver'"
                  label="Үнэлгээ"
                  value="review"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'driver'"
                  label="Багийн мэдээлэл"
                  value="groupInfo"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'driver'"
                  label="Хаягийн сан"
                  value="address"
                ></el-option>
                <el-option
                  v-if="bannerEditData.channel === 'driver'"
                  label="Мэдээлэл"
                  value="info"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="
                bannerEditData.route == 'RestaurantScreen' ||
                  bannerEditData.route == 'productListScreen' ||
                  bannerEditData.route == 'productInfoScreen' ||
                  bannerEditData.route == 'categoryInfoScreen'
              "
              label="Апп дотор шилжих сэрвес"
              prop="route"
            >
              <el-select
                size="mini"
                v-model="pagePassProps"
                @change="getOutletNames()"
                placeholder="Сервис хуудас сонгоно уу"
              >
                <el-option
                  v-for="service in servicesList"
                  :label="service.name_mon"
                  :value="service.name_eng"
                  :key="service.name_eng"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="bannerEditData.route === 'categoryInfoScreenMall'"
              label="Шилжих mall-ын ангилал"
              prop="route"
            >
              <el-tree
                :data="mallCategories"
                :props="defaultProps"
                @node-click="handleNodeClick"
              ></el-tree>
            </el-form-item>
            <el-form-item
              v-if="bannerEditData.route == 'productInfoScreenMall'"
              label="Шилжих mall-ын бүтээгдэхүүн"
              prop="route"
            >
              <el-button
                size="mini"
                @click="getMallProduct('button')"
                type="primary"
                plain
                >Бүтээгдэхүүн сонгох</el-button
              >
            </el-form-item>
            <el-form-item
              v-if="bannerEditData.route == 'brandInfoScreenMall'"
              label="Шилжих mall-ын брэнд"
              prop="route"
            >
              <el-button
                size="mini"
                @click="getMallBrands('button')"
                type="primary"
                plain
                >Брэнд сонгох</el-button
              >
            </el-form-item>
            <el-form-item
              v-if="
                (checkMallProduct.length > 0 &&
                  bannerEditData.route === 'productInfoScreenMall') ||
                  (checkMallBrand.length > 0 &&
                    bannerEditData.route === 'brandInfoScreenMall')
              "
              :label="
                bannerEditData.route === 'productInfoScreenMall'
                  ? 'Сонгосон бүтээгдэхүүн'
                  : 'Сонгосон брэнд'
              "
            >
              <div
                v-for="(product, index) in bannerEditData.route ===
                'productInfoScreenMall'
                  ? checkMallProduct
                  : checkMallBrand"
                :key="index"
              >
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-image
                      :src="
                        bannerEditData.route === 'productInfoScreenMall'
                          ? product.image[0]
                          : product.brand_logo[0]
                      "
                      style="width: 50px; height: 50px"
                    ></el-image>
                  </el-col>
                  <el-col :span="10">
                    <span>{{
                      bannerEditData.route === "productInfoScreenMall"
                        ? product.name_mon
                        : product.brand_monName
                    }}</span>
                  </el-col>
                  <el-col :span="4">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="deleteProduct"
                    ></el-button>
                  </el-col>
                </el-row>
              </div>
            </el-form-item>
            <el-form-item
              v-if="
                bannerEditData.route == 'productListScreen' ||
                  bannerEditData.route == 'productInfoScreen'
              "
              label="Апп дотор харилцагчийн нэр"
              prop="route"
            >
              <el-select
                size="mini"
                v-model="bannerEditData.outlet_id"
                placeholder="Харилцагчаа сонгоно уу"
                @change="getMenusByOutlet()"
                filterable
              >
                <el-option
                  v-for="item in outletList"
                  :key="item._id"
                  :label="item.full_name_eng"
                  :value="item._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="bannerEditData.route == 'productInfoScreen'"
              label="Апп доторх бүтээгдэхүүний нэр"
              prop="route"
            >
              <el-select
                size="mini"
                style="width:300px"
                v-model="bannerEditData.menu"
                placeholder="Бүтээгдэхүүн сонгоно уу"
              >
                <el-option
                  v-for="item in outletMenus"
                  :key="item._id"
                  :label="item.name_mon"
                  :value="item.pkey"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="bannerEditData.route == 'categoryInfoScreen'"
              label="Шилжих категори сонгоно уу"
              prop="route"
            >
              <el-select
                size="mini"
                v-model="pageCategory"
                placeholder="Категори сонгоно уу"
                @change="calculateCategory"
              >
                <el-option
                  v-for="item in categoriesList"
                  :key="item.id"
                  :label="item.name_mon"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form-item>
          <el-form-item label="Эхлэх огноо" required>
            <el-col :span="8">
              <el-form-item prop="start_date">
                <el-date-picker
                  size="mini"
                  type="date"
                  placeholder="Суртчилгаа эхлэх огноо"
                  v-model="bannerEditData.start_date"
                  style="width: 100%;"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1">-</el-col>
            <el-col :span="8">
              <el-form-item prop="end_date">
                <el-date-picker
                  size="mini"
                  type="date"
                  placeholder="Суртчилгаа дуусах огноо"
                  v-model="bannerEditData.end_date"
                  style="width: 100%;"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="Хугацаа тохируулах">
            <el-col :span="8">
              <el-form-item>
                <el-checkbox v-model="bannerEditData.is_time_limit">
                  Цаг тохируулах
                </el-checkbox>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item
            label="Баннер нэр"
            v-if="bannerEditData.is_time_limit === true"
          >
            <el-col :span="8">
              <el-form-item>
                <el-select
                  v-model="bannerEditData.time_limit_mon_name"
                  placeholder="Монгол"
                  @change="changeBannerName(bannerEditData.time_limit_mon_name)"
                >
                  <el-option
                    v-for="(item, index) in BannerNames"
                    :key="index"
                    :value="item.name_mon"
                    >{{ item.name_mon }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <el-select
                  disabled
                  v-model="bannerEditData.time_limit_eng_name"
                  placeholder="English"
                >
                  <el-option
                    v-for="(item, index) in BannerNames"
                    :key="index"
                    :value="item.name_eng"
                    >{{ item.name_eng }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="Нэвтэрсэн байх  эсэх" prop="loginNeeded">
            <el-switch v-model="bannerEditData.loginNeeded"></el-switch>
          </el-form-item>
          <el-form-item label="НУУЦ БАННЕР ЭСЭХ" prop="is_secret">
            <el-switch v-model="bannerEditData.is_secret"></el-switch>
          </el-form-item>
          <el-form-item label="Зураг">
            <base64-upload
              v-if="
                bannerEditData.source_url !== null &&
                  bannerEditData.source_url !== ''
              "
              style="width: 200px; height: 100px"
              :imageSrc="bannerEditData.source_url"
              @change="onChangeImage"
            >
            </base64-upload>
            <a v-else v-loading="loadUpload">
              <base64-upload
                style="width:250px"
                :imageSrc="imageNull"
                @change="onChangeImage"
              ></base64-upload>
            </a>
            <el-input
              size="mini"
              v-model="bannerEditData.source_url"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="Зураг англи">
            <base64-upload
              v-if="
                bannerEditData.source_url_eng !== null &&
                  bannerEditData.source_url_eng !== ''
              "
              style="width: 200px; height: 100px"
              :imageSrc="bannerEditData.source_url_eng"
              @change="onChangeImageEng"
            >
            </base64-upload>
            <a v-else v-loading="loadUploadEng">
              <base64-upload
                style="width:120px"
                :imageSrc="imageNullEng"
                @change="onChangeImageEng"
              ></base64-upload>
            </a>
            <el-input
              size="mini"
              v-model="bannerEditData.source_url_eng"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="Cуртчилгааны суваг" prop="channel">
            <el-select
              size="mini"
              v-model="bannerEditData.channel"
              placeholder="Суртчилгааны суваг сонгоно уу"
            >
              <el-option label="Утас" value="mobile"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="sortNumber" prop="desc">
            <el-input-number
              :precision="2"
              :step="0.1"
              size="mini"
              v-model="bannerEditData.sortNumber"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Cуртчилгааны төлөв" prop="status">
            <el-radio
              v-model="bannerEditData.status"
              label="active"
              border
              size="mini"
              >Идэвхтэй</el-radio
            >
            <el-radio
              v-model="bannerEditData.status"
              label="default"
              border
              size="mini"
              >Идэвхгүй</el-radio
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save()">Засварлах</el-button>
            <el-button :to="{ path: '/banner' }" @click="closeProgress()"
              >Буцах</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      title="Бүтээгдэхүүн"
      :visible.sync="mallProductDialogVisible"
      width="40%"
      height="50%"
      @close="visibleDialog"
    >
      <el-input
        v-model="search_text"
        :placeholder="
          bannerEditData.route === 'productInfoScreenMall'
            ? 'Бүтээгдэхүүн хайх ...'
            : 'Брэнд хайх ...'
        "
        @input="
          Banner.route === 'productInfoScreenMall'
            ? getMallProduct('other')
            : getMallBrands('other')
        "
      >
        <el-button slot="prepend" icon="el-icon-search"> </el-button>
      </el-input>
      <el-row
        class="mb10"
        v-if="bannerEditData.route === 'brandInfoScreenMall'"
      >
        <el-button type="info" size="mini" @click="setBrandCharacter('')">
          Бүх брэнд
        </el-button>
        <el-button
          class="mt10"
          v-for="(character, index) in firstCharacters"
          :key="index"
          type="success"
          size="mini"
          @click="setBrandCharacter(character.first_character)"
        >
          {{ character.first_character }}</el-button
        >
      </el-row>
      <el-table
        size="mini"
        :data="
          bannerEditData.route === 'productInfoScreenMall'
            ? mallProducts
            : bannerEditData.route === 'brandInfoScreenMall'
            ? mallBrands
            : []
        "
        v-loading="productMallLoading"
      >
        <el-table-column label="Зураг">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="
                bannerEditData.route === 'productInfoScreenMall'
                  ? scope.row.image[0]
                  : scope.row.brand_logo[0]
              "
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="Нэр"
          :prop="
            bannerEditData.route === 'productInfoScreenMall'
              ? 'name_mon'
              : 'brand_monName'
          "
        ></el-table-column>
        <el-table-column
          :label="
            bannerEditData.route === 'productInfoScreenMall'
              ? 'Брэнд'
              : 'Дэлгэрэнгүй'
          "
          :prop="
            bannerEditData.route === 'productInfoScreenMall'
              ? 'brand_mon_name'
              : 'brand_description'
          "
        ></el-table-column>
        <el-table-column
          :label="
            bannerEditData.route === 'productInfoScreenMall' ? 'Үнэ' : 'Шимтгэл'
          "
          :prop="
            bannerEditData.route === 'productInfoScreenMall'
              ? 'price'
              : 'fee_percent'
          "
        ></el-table-column>
        <el-table-column
          v-if="bannerEditData.route === 'productInfoScreenMall'"
          label="Үлдэгдэл"
          prop="inventoryCount"
        ></el-table-column>
        <el-table-column label="Сонгох">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-check"
              circle
              plain
              @click="
                bannerEditData.route === 'productInfoScreenMall'
                  ? setMallProduct(scope.row)
                  : setMallBrand(scope.row)
              "
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="text-right mt10"
        background
        :page-size="sizeMall"
        :current-page.sync="currentPage"
        :page-sizes="[5, 10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total"
        @size-change="sizeChangeHandler"
        @current-change="curentPageHandler"
        :total="totalCount"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mallProductDialogVisible = false">Буцах</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import Base64Upload from "vue-base64-upload";
import dayjs from "dayjs";
import { getGroups } from "../utils/auth";
// import { arrayIncludes } from "../helpers/helper";
export default {
  name: "banner",
  components: {
    Base64Upload
  },
  mounted() {
    this.getBannerList();
    this.getServices();
    this.getBannerNames();
    getGroups().then(data => {
      this.role = data;
    });
    this.getAllCategories();
  },
  data() {
    return {
      pagePassPropsBrandFilter: {},
      character: "",
      checkMallBrand: [],
      firstCharacters: null,
      mallProducts: null,
      mallBrands: null,
      productMallLoading: false,
      checkMallProduct: [],
      totalCount: 0,
      mallProductDialogVisible: false,
      currentPage: 0,
      sizeMall: 5,
      field: "",
      sort: "",
      search_text: "",
      defaultProps: {
        children: "childrens",
        label: "name_mon"
      },
      mallCategories: [],
      categoriesList: [],
      pageCategory: "",
      pagePassProps: "",
      statsCount: [
        { key: "home", doc_count: 0 },
        { key: "restaurant", doc_count: 0 },
        { key: "secret", doc_count: 0 }
      ],
      loadUpload: false,
      loadUploadEng: false,
      page: "",
      imageNull: "none",
      imageNullEng: "none",
      dialogVisible: false,
      Banner: [],
      bannerEditData: {
        outlet_name: null,
        menu: null,
        category: null,
        is_time_limit: false,
        outlet_id: null
      },
      sendEdit: false,
      popupImage: "",
      rules: {
        title: [
          {
            required: true,
            message: "Та зарлал нэрээ оруулна уу",
            trigger: "blur"
          },
          {
            min: 3,
            message: "Хамгийн багадаа 3 үсэг оруулна уу",
            trigger: "blur"
          }
        ]
      },
      pageSize: 200,
      fromRecord: 0,
      serviceCategory: "",
      outlet: "",
      outletList: [],
      outletMenus: [],
      pageCount: 0,
      bannerList: null,
      size: 10,
      search: "",
      role: null,
      from: 1,
      status: "active",
      BannerNames: []
    };
  },
  methods: {
    setBrandCharacter(character) {
      this.character = character;
      this.getMallBrands("other");
    },

    setMallBrand(data) {
      this.checkMallBrand = [];
      this.checkMallBrand.push(data);
      this.mallProductDialogVisible = false;
      this.pagePassPropsBrandFilter = data;
    },

    getMallBrands(type) {
      if (type === "button") {
        this.openFullScreenLoader(true);
      } else {
        this.productMallLoading = true;
      }
      service
        .getMallBrands(
          this.character,
          this.search_text,
          this.sizeMall,
          this.currentPage
        )
        .then(response => {
          if (response.status === "success") {
            this.mallBrands = response.data;
            this.totalCount = response.total;
            this.firstCharacters = response.firstCharacters;
            this.mallProductDialogVisible = true;
            if (type === "button") {
              this.openFullScreenLoader(false);
            } else {
              this.productMallLoading = false;
            }
          } else {
            this.mallProductDialogVisible = false;
            if (type === "button") {
              this.openFullScreenLoader(false);
            } else {
              this.productMallLoading = false;
            }
          }
        });
    },

    getOneProductMall(id) {
      service.getOneProductMall(id).then(response => {
        if (response.status === "success") {
          this.checkMallProduct = [];
          this.checkMallProduct.push(response.data);
        }
      });
    },

    getOneBrandMall(id) {
      service.getOneBrandMall(id).then(response => {
        if (response.status === "success") {
          this.checkMallBrand = [];
          this.checkMallBrand.push(response.data);
        }
      });
    },

    deleteProduct() {
      if (this.bannerEditData.route === "productInfoScreenMall") {
        this.checkMallProduct = [];
        this.bannerEditData.menu = "";
      } else {
        this.checkMallBrand = [];
      }
    },

    visibleDialog() {
      this.currentPage = 0;
      this.character = "";
      this.search_text = "";
    },

    setMallProduct(data) {
      this.checkMallProduct = [];
      this.mallProductDialogVisible = false;
      this.bannerEditData.menu = data.productId;
      this.checkMallProduct.push(data);
    },

    sizeChangeHandler(size) {
      this.sizeMall = size;
      this.getMallProduct("other");
    },

    curentPageHandler(page) {
      this.currentPage = page;
      this.getMallProduct("other");
    },

    getMallProduct(type) {
      if (type === "button") {
        this.openFullScreenLoader(true);
      } else {
        this.productMallLoading = true;
      }
      const tempBody = {
        from: this.currentPage,
        size: this.sizeMall,
        maxPrice: 0,
        minPrice: 0,
        search_text: this.search_text,
        filters: [],
        field: this.field,
        sort: this.sort
      };
      service.getMallProduct(tempBody).then(response => {
        if (response.status === "success") {
          this.mallProducts = response.data.products.hits;
          this.totalCount = response.data.products.total;
          this.mallProductDialogVisible = true;
          if (type === "button") {
            this.openFullScreenLoader(false);
          } else {
            this.productMallLoading = false;
          }
        } else {
          this.mallProductDialogVisible = false;
          if (type === "button") {
            this.openFullScreenLoader(false);
          } else {
            this.productMallLoading = false;
          }
        }
      });
    },
    handleDeleteBanner(id) {
      this.$confirm("Устгахдаа итгэлтэй байна уу?", id, {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(async () => {
          service.deleteBanner(id).then(response => {
            if (response.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Суртчилгаа устгагдлаа",
                type: "success"
              });
              this.getBannerList();
            } else if (response.data.status === "unsuccess") {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Суртчилгаа устгахад алдаа гарлаа " +
                  " " +
                  response.error.message,
                type: "warning"
              });
            }
            this.getBadges();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Буцлаа"
          });
        });
    },
    handleNodeClick(data) {
      this.pagePassProps = data.uniq_id;
    },
    getAllCategories() {
      service.getAllCategories().then(servicesResponse => {
        if (servicesResponse.data.status === "success") {
          this.mallCategories = servicesResponse.data.data;
        }
      });
      this.getCategoriesList();
    },
    getServices() {
      service.getServices().then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    },
    changePage() {
      if (this.bannerEditData.page == "sales") {
        this.bannerEditData.position = "discount";
      } else if (this.bannerEditData.page == "order") {
        this.bannerEditData.position = "order";
      } else if (this.bannerEditData.page == "game") {
        this.bannerEditData.position = "game";
      } else {
        this.bannerEditData.position = "";
      }
    },
    tableRowClassName({ row }) {
      let a = dayjs();
      if (a.diff(row.end_date) > 0) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },
    clearParam() {
      (this.bannerEditData.outlet_name = null),
        (this.pagePassProps = ""),
        (this.bannerEditData.menu = null),
        (this.pageCategory = "");
    },
    calculateCategory() {
      this.bannerEditData.category = [];
      this.categoriesList.forEach(element1 => {
        if (this.pageCategory === element1.id) {
          this.bannerEditData.category.push(element1);
        }
      });
    },
    getCategoriesList() {
      service.getMainCategories().then(res => {
        this.categoriesList = [];
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].service == this.pagePassProps) {
            this.categoriesList.push(res.data.data[i]);
          }
        }
      });
    },
    getMenusByOutlet() {
      service.getMenusByOutlet(this.bannerEditData._id).then(menuResponse => {
        this.outletMenus = menuResponse.data.data;
      });
    },
    getOutletNames() {
      service.getOutletNames(this.pagePassProps).then(outletResponse => {
        this.outletList = outletResponse.data.data;
      });
      this.getCategoriesList();
    },
    formatter(row) {
      let convertDate = row.start_date.substring(-6, 10);
      return convertDate;
    },
    formatterEndDate(row) {
      let convertDate = row.end_date.substring(-6, 10);
      return convertDate;
    },
    closeProgress() {
      this.checkMallProduct = [];
      this.bannerEditData = {};
      this.bannerEditData.source_url = "";
      this.bannerEditData.source_url_eng = "";
      this.getBannerList();
      this.dialogVisible = false;
    },
    deleteOpenDylog(id) {
      this.deleteItemId = id;
      this.deleteDialogVisible = true;
    },
    showEdit(data) {
      this.bannerEditData = {};
      this.bannerEditData = data;
      if (this.bannerEditData.position == "discount") {
        this.bannerEditData.page = "sales";
      }
      if (this.bannerEditData.route === "categoryInfoScreenMall") {
        data.passProps.data.filters.forEach(element => {
          this.pagePassProps = element.key;
        });
      } else {
        this.pagePassProps = data.passProps.serviceData;
      }
      if (this.bannerEditData.category !== null) {
        this.bannerEditData.category.forEach(element1 => {
          this.pageCategory = element1;
        });
      }
      if (this.bannerEditData.route === "productInfoScreenMall") {
        this.getOneProductMall(data.menu);
      }
      if (this.bannerEditData.route === "brandInfoScreenMall") {
        data.passProps.data.filters.forEach(element => {
          this.pagePassPropsBrandFilter = element;
        });
        this.getOneBrandMall(this.pagePassPropsBrandFilter.key);
      }
      this.dialogVisible = true;
      this.getOutletNames();
      this.getMenusByOutlet();
    },
    onChangeImage(file) {
      this.loadUpload = true;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("Зурагны хэмжээ ихдээ 1MB байх ёстой!");
        this.imageNull = "none";
        this.loadUpload = false;
      } else {
        if (!file.name) {
          this.$message.error("Зурагны aнгли нэрийг заавал оруулах ёстой!");
          this.imageNull = "none";
          this.loadUpload = false;
        } else {
          if (
            this.bannerEditData.page == "restaurant" &&
            this.bannerEditData.position == "bottom"
          ) {
            let payload = {};
            payload.file = file.base64;
            payload.imageName = file.name;
            payload.size = "1200x300";
            service.uploadAppSize(payload).then(res => {
              this.bannerEditData.source_url = res.img_url;
              this.loadUpload = false;
            });
          } else {
            let payload = {};
            payload.file = file.base64;
            payload.bannerName = file.name;
            service.uploadBannerImage(payload).then(res => {
              this.bannerEditData.source_url = res.data.data.img_url;
              this.loadUpload = false;
            });
          }
        }
      }
      return isLt2M;
    },
    onChangeImageEng(file) {
      this.loadUploadEng = true;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("Зурагны хэмжээ ихдээ 1MB байх ёстой!");
        this.imageNullEng = "none";
        this.loadUploadEng = false;
      } else {
        if (!file.name) {
          this.$message.error("Зурагны aнгли нэрийг заавал оруулах ёстой!");
          this.imageNullEng = "none";
          this.loadUploadEng = false;
        } else {
          if (
            this.bannerEditData.page == "restaurant" &&
            this.bannerEditData.position == "bottom"
          ) {
            let payload = {};
            payload.file = file.base64;
            payload.imageName = file.name;
            payload.size = "1200x300";
            service.uploadAppSize(payload).then(res => {
              this.bannerEditData.source_url_eng = res.img_url;
              this.loadUploadEng = false;
            });
          } else {
            let payload = {};
            payload.file = file.base64;
            payload.bannerName = file.name;
            service.uploadBannerImage(payload).then(res => {
              this.bannerEditData.source_url_eng = res.data.data.img_url;
              this.loadUploadEng = false;
            });
          }
        }
      }
      return isLt2M;
    },
    save() {
      this.$refs.bannerEditData.validate(valid => {
        if (valid) {
          let setPassProps =
            this.bannerEditData.route === "categoryInfoScreenMall"
              ? {
                  channel: "toktokmall",
                  data: {
                    filters: [
                      {
                        key: this.pagePassProps
                      }
                    ],
                    name_eng: "Category",
                    name_mon: "Ангилал"
                  }
                }
              : {
                  serviceData: this.pagePassProps
                };
          this.bannerEditData.passProps = setPassProps;
          if (this.bannerEditData.route === "brandInfoScreenMall") {
            this.bannerEditData.passProps = [];
            this.bannerEditData.passProps = {
              channel: "toktokmall",
              data: {
                filters: [
                  {
                    key: this.pagePassPropsBrandFilter.brand_id,
                    brand_logo: this.pagePassPropsBrandFilter.brand_logo,
                    name_eng: this.pagePassPropsBrandFilter.brand_engName,
                    name_mon: this.pagePassPropsBrandFilter.brand_monName
                  }
                ],
                name_eng: "Brand",
                name_mon: "Бренд"
              }
            };
          } else this.bannerEditData.passProps.serviceData = this.pagePassProps;
          if (this.bannerEditData.position == "discount") {
            this.bannerEditData.page = "home";
          }
          if (this.bannerEditData.type === "inviteFriend") {
            this.bannerEditData.route = "inviteFriend";
          } else if (this.bannerEditData.type === "game") {
            this.bannerEditData.route = "gameScreen";
          }
          this.outletList.find(el => {
            if (el.outlet_name === this.bannerEditData.outlet_name) {
              this.bannerEditData.outlet_id = el._id;
            }
          });
          service
            .updateBanner(this.bannerEditData.id, this.bannerEditData)
            .then(response => {
              if (response.data.status === "success") {
                this.$notify({
                  title: "Амжилттай",
                  message: "Суртчилгааны мэдээлэл засагдлаа",
                  type: "success"
                });
                this.closeProgress();
              } else {
                this.$notify({
                  title: "Амжилтгүй",
                  message:
                    "Суртчилгааны мэдээлэл засахад алдаа гарлаа " +
                    " " +
                    response.error.message,
                  type: "warning"
                });
              }
            });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    getOutletById(id) {
      this.$router.push({
        path: "edit-banner",
        query: {
          id: id
        }
      });
      this.$notify({
        title: "Амжилттай",
        message: id + " " + "мэдээллийг засах",
        type: "success"
      });
    },
    filterTag(value, row) {
      return row.status === value;
    },
    getBannerList() {
      this.openFullScreenLoader(true);
      this.statsCount = [
        { key: "home", doc_count: 0 },
        { key: "restaurant", doc_count: 0 },
        { key: "secret", doc_count: 0 }
      ];
      if (this.search === "" && this.status !== "default") {
        this.status = "active";
      }
      let payload = {};
      payload.status = this.status;
      payload.from = this.from;
      payload.search = this.search;
      service
        .getAllBanners(this.size, this.from, this.status, this.search)
        .then(res => {
          if (res.data.status === "success") {
            this.bannerList = res.data.data.banners;
            this.pageCount = res.data.data.total;
            for (let i = 0; i < res.data.data.statsCount.length; i++) {
              if (res.data.data.statsCount[i].page === "home") {
                this.statsCount[0].doc_count =
                  this.statsCount[0].doc_count +
                  res.data.data.statsCount[i].count;
              }
              if (res.data.data.statsCount[i].page === "restaurant") {
                this.statsCount[1].doc_count =
                  this.statsCount[1].doc_count +
                  res.data.data.statsCount[i].count;
              }
              if (res.data.data.statsCount[i].is_secret === 1) {
                this.statsCount[2].doc_count =
                  this.statsCount[2].doc_count +
                  res.data.data.statsCount[i].count;
              }
            }
          } else {
            this.$notify({
              title: "Алдаатай",
              message: "",
              type: "warning"
            });
          }
        });
      this.openFullScreenLoader(false);
    },
    filterTag1(value) {
      this.page = value;
    },
    setPage(val) {
      this.from = val;
      this.getBannerList();
    },
    searchBanner() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.from = 1;
        this.status = "";
        this.getBannerList();
      }, 1000);
    },
    activeSearch() {
      this.from = 1;
      this.getBannerList();
    },
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    getBannerNames() {
      service.getTimeLimitNamesBanner().then(res => {
        this.BannerNames = res.data.data.rows;
      });
    },
    changeBannerName(data) {
      this.BannerNames.forEach(el => {
        if (el.name_mon === data) {
          this.bannerEditData.time_limit_eng_name = el.name_eng;
        }
      });
    }
  }
};
</script>
<style>
.el-table .warning-row {
  background: rgb(238, 235, 235);
}

.el-table .success-row {
  background: #ffffff;
}
</style>
